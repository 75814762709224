.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #43425d;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}
.sidenav-right {
    height: 70%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 50px; /* Stay at the top */
    right: 0;
    background-color: #b1b1b1;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

.bill-review-section {
    line-height: 50px;
    border-bottom: 1px solid;
    color: #707070;
}

.bill-review-section > button {
    font-size: 12px !important;
    line-height: 10px !important;
}

.page-name-section {
    background-color: #3c3b54;
}
 

.menu-items > a {
    width: 100%;
    height: 40px;
    display: inline-block;
    text-decoration: none !important;
    line-height: 36px;
    padding-left: 10px;
    font-size: 14px;
    border-left: 4px solid #43425d;
}

.menu-items > a > span {
    margin-left: 10px;
}

.selected-item, .menu-items > a:hover {
    background-color: #3c3b54;
    color: #9796a2 !important;
    border-left: 4px solid #2699fb !important;
}

.menu-items > a, a:hover, a:visited, a:link, a:active {
    color: #cdcdd4;
}

.full-menu > svg {
    font-size: 12px;
}


.small-menu > svg {
    font-size: 18px;
    margin-left: 4px;
    margin-top: 10px;
}

.small-menu > span {
    display: none;
}

.page-name-section > span > svg {
    margin-top: 14px;
    font-size: 22px;
    color: white;
}


.close-menu-btn {
    position: fixed;
    color: blue;
    top: 26px;
    left: 184px;
    font-size: 30px;
    z-index: 2;
    border-radius: 16px;
    border-color: blue;
    border: 1px dashed;
    background-color: white;
    opacity: 0;
}

.close-menu-btn:hover {
    color:cornflowerblue !important;
    opacity: 0.9;
}
