@import "../../../scss/york-color-palette.scss";
@import "../../../../node_modules/bootstrap/scss/bootstrap";

#loading-container {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 2rem;
  z-index: $zindex-tooltip + 2;
}

#loading-overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $zindex-tooltip + 1;
  background-color: $slate-500;
  opacity: 0.1;
}