/* header css */

.refresh-btn {
  margin-left: 20px;
  color: #4ad991;
}

.refresh-btn:hover {
  color: #66ce9a !important;
}

.page-name {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 2px;
  line-height: 50px;
}

.page-name-section,
.bill-review-section,
.header-section,
.header-user,
.header-menu,
.settingsButton {
  height: 50px;
}

.header-section {
  background-color: #ffffff;
  padding-left: 30px !important;
}

.header-name {
  font-weight: 600;
  color: #67696b;
  font-size: 18px;
  line-height: 50px;
}

.profileDropdown {
  width: 150px;
}

.fab-btn {
  cursor: pointer;
}

.fab-btn:hover {
  color: #797f85;
}

/*.form-control {
  background-color: white !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}*/

.user-wrapper {
  color: #7e7e81 !important;
  background-color: white !important;
  border-color: white !important;
}

.header-user,
.header-menu {
  line-height: 50px;
}

.header-user .dropdown-menu.show {
  line-height: 10px;
  width: 6rem;
  min-width: 6rem;
  font-size: 12px;
  border-radius: 0;
  padding: 4px 0;
}

.header-menu .dropdown-menu.show {
  line-height: 24px;
  min-width: 6rem;
  font-size: 12px;
  border-radius: 0;
  padding: 4px 0;
}

.user-wrapper:focus {
  box-shadow: none !important;
}

.user-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 16px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 30px 30px;
}

.logo {
  height: 55px;
  margin: 25px 0 50px 0;
}

.orangeArrow {
  height: 35px;
  left: -20px;
  position: absolute;
  width: 26px;
}

.centeredContainer {
  margin: 150px auto;
  width: 600px;
}

.marginTop25 {
  margin-top: 25px;
}

.lineArt {
  position: fixed;
  bottom: 0;
  right: 0;
}

.client-ppo-table {
  max-height: 180px;
  overflow-y: auto;
  overflow-x: hidden;
}

.odg-lookup-table {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 10px;
}

.button-footer.col-lg-4 {
  display: flex;
  justify-content: flex-end;
}

.speciality {
  text-align: right;
}

.state-modal {
  text-align: right;
}

.text-center {
  text-align: center;
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fee-lookup-table {
  height: 180px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.fee-lookup-table table thead tr:nth-child(1) th {
  text-align: left;
  position: sticky;
  top: 0px;
}

.card-body {
  padding: 0px !important;
}

#odgSubmit {
  float: right;
  margin-top: 50px;
}

#printEorDiv {
  background-color: lightblue;
  width: 100%;
  height: 200px;
  margin-left: 15px;
  margin-right: 15px;
}
.CliamAdjust {
  padding-left: 17px;
}
.CliamAdjustNote {
  padding-left: 20px;
}

.printEorModal {
  width: 75%;
  margin-left: 15%;
}

.userEor {
  padding-left: 10px;
}

.billEndEor {
  padding-left: 5px;
}

#printFlag {
  padding-right: 35%;
}

.bill-list-hover:hover {
  cursor: pointer;
  background-color: #2699fb;
}

.CliamAdjust {
  padding-left: 17px;
}
.CliamAdjustNote {
  padding-left: 20px;
}

.badge-document-icon {
  cursor: pointer;
}
#id-user {
  width: 500px !important;
}
.li a span {
  /* display:inline !important;  */
  width: 500px !important;
}

.typeAhead li {
  display: unset;
  float: unset;
  padding-right: 0px;
}

.typeHeadSpan::after {
  position: absolute;
  content: '\25bc';
  top: 10px;
  right: 17px;
  z-index: 4;
  font-size: 10px;
}

.real-element[type='text'] {
  background: transparent;
  position: absolute;
  left: 6px;
  color: transparent;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 95%;
  top: 1px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input.real-element {
  caret-color: black !important;
  background-color: #ffffff;
}

.show-real[type='text'] {
  position: absolute;
  left: 6px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 95%;
  top: 1px;
  outline: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.real-element[type='text']:focus {
  border-color: #7483a1;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 70, 89, 0.25);
}

.select__menu.css-26l3qy-menu {
  z-index: 9999999;
}

.active-green-circle {
  stroke: white;
  stroke-width: 30px;
  color: green;
}

.file-attachment-remove-button.btn.btn-secondary {
  padding-right: 57px;
  padding-left: 57px;
  margin-right: 24px;
}

.file-attachment-button.btn.btn-secondary {
  padding-right: 57px;
  padding-left: 57px;
}
.scrolled {
  position: fixed;
  top: 0;
}

.table thead tr:nth-child(1) th {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: relative;
  overflow: hidden;
}

.resize-border {
  height: 150px !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.pgo-check {
  margin-left: 56px;
  margin-top: 28px;
}

.exitButton {
  position: absolute;
  right: 11px;
  top: 11px;
}

.claim-button-active {
  color: #ffffff;
  background-color: #505d78;
  border-color: #4b5770;
  box-shadow: 0 0 0 0.2rem rgba(119, 133, 160, 0.5);
}

.claimant-info {
  display: -webkit-flex !important;
  display: block !important;
}

.rent-button-active {
  color: #ffffff;
  background-color: #1348bb;
  border-color: #054fee;
  box-shadow: 0 0 0 0.2rem #054fee;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

#clientMaintenanceLeft {
  background-color: #a9b5cc;
  border: thick solid #a9b5cc;
}

#clientMaintenanceLeftSiteNamecol {
  background: white;
}

#ClientMaintenanceRightHeader {
  background-color: #a9b5cc;
  border: thick solid #a9b5cc;
}

/* #clientMaintenanceLeftSiteNameHeight {
  height: 1000px;
} */

li.MuiTreeItem-root {
  width: 100%;
}
.text-colour {
  color: #bdbd07;
}
input.error
{
    border: 1px solid #eb516d;
}
select.error{
  border: 1px solid #eb516d;
}

.careworksTrainingModal {
  height: 580px;
}

.tooltip {
  pointer-events: none;
}

.dropdown-item > .dropdown > .dropdown-toggle {
  padding-left: 0;
  background: transparent !important;
  color: #212529 !important;
}

.dropdown-item > .dropdown > .dropdown-toggle:hover {
  background: transparent !important;
  color: #212529;
}

.dropdown-item > .submenu > button, 
.dropdown-item > .submenu > button:hover,
.dropdown-item > .submenu > button:active,
.dropdown-item > .submenu > button:focus  {
   padding: 0;
   width: 100%;
}

.submenu > button{
  text-align: left;
}

.thresholdAmount {
  width: 20% !important;
  display: inline !important;
}