li {
    display: inline;
    float: left;
    padding-right: 10px;
}

ul a span{
    color:black;
    font-size:10px;
    display:block;
    text-transform:uppercase;
    letter-spacing:1px;
}

