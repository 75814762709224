.modal {
  width: 75%;
  margin-left: 15%;
}

.reportOptions {
  background-color: lightblue;
}

.reportOptionsItem {
  margin-bottom: 0.5rem;
}

.submitButtons {
  float: right;
}