@import "../../../scss/york-color-palette.scss";
@import "../../../../node_modules/bootstrap/scss/bootstrap";

.autocomplete-wrapper {
  position: relative;

  .input-caret {
    position: relative;
    display: inline-block;
    &:hover {
      cursor: pointer;
    }
  }

  .input-caret::after {
    position: absolute;
    content: "\25bc";
    top: 8px;
    right: 8px;
    z-index: 4;
    font-size: 14px;
  }

  .datalist-ul {
    position: absolute;
    list-style: none;
    border: $input-border-width solid $input-border-color;
    border-radius: $border-radius;
    background: $white;
    overflow: hidden;
    overflow-y: auto;
    z-index: $zindex-dropdown;

    li {
      display: block;
      text-align: left;
      padding: $input-padding-y $input-padding-x;
      cursor: pointer;
      width: 100%;

      &.suggested {
        border-bottom: $input-border-width solid $input-border-color;
      }

      &.highlighted {
        background: $light-grey;
      }
    }
  }
}
