@import "../../../../scss/york-color-palette.scss";

.button-link {
  color: $primary-blue;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}
.bill-review-summary {
  span:first-child {
    margin-right: 40px;
  }

  button {
    margin: 0px 8px;
  }
}

.select-action {
  display: flex;
  label {
    margin-bottom: 0;
    float: left;
  }

  .custom-checkbox.custom-control {
    float: left;
    margin-left: 5px;
  }
}

.odg-lookup-modal {
  &.modal-lg {
    word-break: break-word;
    max-width: 1400px;
    width: 80%;
  }
}

.fee-lookup-modal {
  width: 100%;
}