.WIPTable table {
  width: 600px;
  table-layout: auto;
  border-collapse: collapse;
}

.WIPTable table td,
.WIPTable table th {
  padding-left: 10px;
  padding-right: 10px;
  min-width: 75px;
  white-space: nowrap;
}

.WIPTable table td:first-child,
.WIPTable table th:first-child {
  min-width: 20px;
}

.WIPTable table td:last-child,
.WIPTable table th:last-child {
  width: 100%;
}

.WIPTable table th:nth-child(2),
.WIPTable table td:nth-child(2) {
  width: 200px;
  display: block;
}

.WIPTable table td:nth-child(2) .indent {
  padding-left: 20px;
}

.WIPTable thead,
.WIPTable tbody {
  display: block;
}

.WIPTable tbody {
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.BillingTable table {
  width: 700px;
  table-layout: auto;
  border-collapse: collapse;
}

.BillingTable table td,
.BillingTable table th {
  padding-left: 10px;
  padding-right: 10px;
  min-width: 75px;
  white-space: nowrap;
}

.BillingTable table td:first-child,
.BillingTable table th:first-child {
  min-width: 20px;
}

.BillingTable table td:last-child,
.BillingTable table th:last-child {
  width: 100%;
}

.BillingTable table th:nth-child(2),
.BillingTable table td:nth-child(2) {
  width: 200px;
  display: block;
}

.BillingTable table td:nth-child(2) .indent {
  padding-left: 20px;
}

.BillingTable thead,
.BillingTable tbody {
  display: block;
}

.BillingTable tbody {
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
