@import "york-color-palette";

$font-size-base: 0.8rem;
$grid-gutter-width: 16px;
$theme-colors: (
        "primary": $slate-700,
        "secondary": $slate-500,
        "success": $green,
        "warning": $yellow,
        "danger": $bright-orange,
        "info": $medium-blue,
        "light": $white,
        "dark": $dark-grey
);

@import '../../node_modules/bootstrap/scss/bootstrap';

.zebra-form-group {
  @extend .mb-2;
  @extend .mr-sm-2;
  @extend .mb-sm-0;

  label {
    @extend .mr-sm-2;
  }
}

.findBtn {
  position: absolute;
  bottom: 0;
}

.grey-text {
  color: $medium-grey;
}

.slate-500-text {
  color: $slate-500;
}

.green-text {
  color: $green;
}

.yellow-text {
  color: $yellow;
}

.red-text {
  color: $bright-orange;
}

.black-text {
  color: $dark-grey;
}

.blue-text {
  color: $primary-blue;
}

.italic-text {
  font-style: italic;
}

.underline-text {
  text-decoration: underline
}

.cursor-hover {
  &:hover {
      cursor: pointer;
  }
}

.react-datepicker-popper {
  z-index: 100;
}

.bold-text  {
  font-weight: bolder
}

.resize-border {
  cursor: ew-resize;
  display: inline-block;
  width: 2px;
  margin: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  background-color: $light-grey;
}

.invalid-tooltip {
  word-break: break-word;
}

.table-hover {
  tbody {
    tr:hover {
      td, th {
        background-color: #b2e1ff;
      }
    }
  }
}

.react-datepicker__input-container input.form-control {
  font-size: $font-size-base;
  padding: $input-btn-padding-x $input-btn-padding-y;
}

.custom-radio-error::before {
  content: '';
  height: 100%;
  width: 100%;
  display: block;
  border-radius: 50%;
  box-shadow: 0 0 0 1px red inset;
}
