#alert-overlay {
  position: fixed;
  bottom: 0;
  z-index: 2000;

  width: 100%;
}

#alert-container {
  margin: 2rem;
  width: 70vw;
}
