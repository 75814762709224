.tin-input-group {
  .input-group {
    .form-control {
      &:not(:last-child) {
        flex: 1 1 auto;
      }
      &:not(:first-child) {
        flex: 1 1 auto;
      }
    }
  }
}

.tin-label-group {
  display: flex;
  label {
    &:not(:last-child) {
      flex: 2 1 auto;
    }
    &:not(:first-child) {
      flex: 1 1 auto;
    }
  }
}

.expert-info-modal {
  .modal-footer {
    justify-content: space-between;
  }

  &.modal-lg {
    max-width: 900px;
    width: 80%;
  }
}

.credentialling-document-table {
  max-height: 330px;
  overflow: auto;
}
