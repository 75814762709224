@import "../../../../scss/york-color-palette.scss";

.find-expert-popover {
    span {
        &:hover {
            color: $bright-orange;
            cursor: pointer;
        }
    }
    .close {
        margin-left: 5px;
    }
}

.fix-height-card {
    display: block;
    height: 300px;
    .card-body {
        overflow: scroll;
    }
}

.small-fix-height-card {
    display: block;
    height: 100px;
    .card-body {
        overflow: scroll;
    }
}

.row-clickable table tbody tr {
    &:hover {
        cursor: pointer;
    }
}
