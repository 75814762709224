.adjust-label {
  margin-left: 20px;
}

.align-balance-without-label {
  margin-top: 32px;
  button {
    width: 100%;
  }
  label {
    margin-top: 8px;
    margin-left: 10px;
  }
}

.bill-approval-alert,
.clients-alert {
  color: red;
  font-style: italic;
}

.list-group-item:hover {
  background-color: lightgray;
}

.bill-approval-alert-row {
  padding-bottom: 10px;
}

.comment-box {
  padding-top: 20px;
  min-height: 200px;
}

.fixed-panel {
  min-height: 200px;
  height: 200px;
  overflow-y: scroll;
}

.fixed-panel2 {
  min-height: 200px;
  height: 500px;
  overflow-y: scroll;
}
.nav-item .nav-link {
  cursor: pointer;
}

.table thead tr:nth-child(1) th {
  position: sticky;
  top: 0;
  z-index: 20;
}

.modal {
  background-color: transparent;
  box-shadow: none;
  color: #000;
  margin: 0 auto;
  max-width: none;
  padding: 0;
  position: fixed;
  top: 50%;
  transform: none;
  width: 100%;
  word-break: break-all;
  z-index: 1050;
}
