tbody tr:nth-child(odd) {
  background-color: #dadada;
}

.recordTable {
  overflow-x: auto;
  overflow-y: hidden;
  height: auto;
  margin-bottom: 2rem;
  display: block;
  white-space: nowrap;
}

.recordEmptyTable {
  overflow-x: auto;
  overflow-y: hidden;
  height: 200px;
  margin-bottom: 2rem;
}

.recordTable table,
.recordEmptyTable table {
  border: none;
}
.recordTable table tbody,
.recordEmptyTable table tbody {
  display: block;
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.recordTable table thead,
.recordTable table tbody tr,
.recordEmptyTable table thead,
.recordEmptyTable table tbody tr {
  display: table;
  table-layout: fixed;
}
.recordTable th,
.recordTable td,
.recordEmptyTable th,
.recordEmptyTable td {
  padding: 8px 16px;
  width: 320px !important;
}
.recordTable th,
.recordEmptyTable th {
  background: #43425d;
  color: #dadada;
}

.recordTable table thead,
.recordEmptyTable table thead {
  width: 99%;
}

.recordTable table tbody tr,
.recordEmptyTable table tbody tr {
  width: 100%;
}