$white: #ffffff;

$dark-grey: #2a2927;
$medium-grey: #a9abad;
$light-grey: #ecf1f3;

$medium-blue: #6f89a4;
$light-blue: #bed4e9;

$york-orange: #f28531;

$green: #78b28d;
$yellow: #f8ab00;
$bright-orange: #e8553a;
$deep-red: #560b25;

$slate-900: #252c38;
$slate-800: #2d3642;
$slate-700: #3c4659;
$slate-600: #4c5a72;
$slate-500: #5f6f8f;
$slate-400: #7789a8;
$slate-300: #8da1be;
$slate-200: #bac9dc;
$slate-100: #d6dfe9;

$primary-blue: #0000ff;
