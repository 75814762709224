.maintenanceTable table {
    white-space: nowrap;
}

.maintenanceTable table td {
    overflow: hidden;
    max-width: 1px;
}

.maintenanceTable table td div.DatePicker {
    display: flex;
}