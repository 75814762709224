.serviceTab, .serviceTabActive {
    font-size: 12px;
    color: black;
    padding: 5px 5px !important;
}

.serviceTabActive {
    font-weight: bold;
}

.serviceTabDisabled {
    font-size: 12px;
    color: gray;
    padding: 5px 5px !important;
}

.serviceTabDisabled:hover {
    color: gray;
}