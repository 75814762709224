.dropdown-multi-select-wrapper {
  display: flex;
  position: relative;

  .show-dropdown-button {
    width: 45px;
  }

  .options-list-wrapper {
    position: absolute;
    top: 40px;
    height: 230px;
    overflow-y: auto;
    z-index: 21;
    background-color: #fff;
  }
}