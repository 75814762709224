.client-records-table {
    table tbody tr {
        &:hover {
            cursor: pointer;
        }
    }

    .pagination {
        display: inline-block;
        .page-item {
            margin-bottom: 1rem;
        }
    }
}

.full-width .react-datepicker-wrapper {
    width: 100%;
}

.right-one-third {
    .input-group {
        .form-control {
            &:not(:last-child) {
                flex: 3 1 auto;
            }
            &:not(:first-child) {
                flex: 1 1 auto;
            }
        }
    }
}