.vendor-form {
    margin: 20px;
}

.form-section {
    border: 1px solid #a3a9b0;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    position: relative;
}

.form-section h5 {
    color: #2a3f54;
    margin-bottom: 15px;
    font-weight: bold;
    background-color: #e9eef3;
    display:inline-block
}

.form-section.form-group label {
    font-weight: 600;
    color: #2a3f54
}

.form-section.form-group input,
.form-section.form-group select {
    background-color: #ffffff;
    border: 1px solid #ccc;
    font-size: 14px;
}

button.save-button {
    margin-top: 0;
}

.red-text {
    color: red;
}

 .form-check {
    margin-top: 32px;
 }

 .form-group {
    position: relative;
    margin-bottom: 1rem;
 }

 .input-wrapper {
    position: relative;
 }

 .suggestion-list {
    position: absolute;
    top: 100%;
    left:0;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
    margin-top: 0.5rem;
 }

 .suggestion-list .list-group-item {
    cursor: pointer;
    padding: 0.5rem;
 }

 .tabs-container {
    margin-top: 10px;
 }

 .tab-button {
    padding: 10px 20px;
    cursor: pointer;
    font-weight : 500;
    color: #5f6f8f;
    text-align: center;
 }

 .tab-button.active {
    color: black;
    font-weight: bold;
 }

 .tab-button:hover {
    color: #000;
 }

