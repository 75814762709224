.bill-calendar-view {
  .rbc-calendar {
    height: 700px;
  }
  .rbc-event {
    background-color: initial;
    padding: 0;
  }
  .rbc-event-label {
    display: none;
  }

  .rbc-row-content {
    position: static;
  }

  .event-detail-tooltip-wrapper {
    background-color: #0D9CCA;
    color: #fff;
    font-size: 13px;
    opacity: .9;
    text-align: center;
    z-index: 4;
  }

  .event-detail-wrapper {
    height: 18px;
    border-radius: 4px;
    padding: 1px 5px;
    font-size: 13px;
  }
  .month-name {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 3px;
    background-color: #5a5a5a;
    color: #fff;
  }

  .day {
    border-bottom: solid 1px black;
    padding: 3px;
  }

  .week-separator {
    border: 1px solid #000;
  }
  .month-border-left {
    border-left: solid 1px black;
  }
  .month-border-right {
    border-right: solid 1px black;
  }
  .yearly-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    background-color: #3174ad;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }
}