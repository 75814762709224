.contacts-tab {
    padding: 10px;
    display: flex;
    height: 100%;
  }
  
  .contacts-list {
    padding: 10px;
    width: 350px;
    border-right: 1px solid #ccc;
  }
  
  .contacts-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e7edf5;
    padding: 8px;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .contact-names {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  
  .contact-item {
    padding: 8px 12px;
    background-color: #ffffff;
    margin-bottom: 5px;
    border-radius: 4px;
    text-align: left;
    cursor: pointer;
    color: #333;
    width: 100%; /* Ensures all items have the same width */
    box-sizing: border-box; /* Includes padding in the width calculation */
  }
  
  .contact-item:hover {
    background-color: #c3cfd9;
    font-weight: bold;
  }
  
  .contact-item.selected {
    background-color: #b2e1ff;
    font-weight: bold;
  }
  
  .contact-info-form {
    flex: 1;
    padding: 10px;
    background-color: #ffffff;
  }
  
  .contact-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e7edf5;
    padding: 10px;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .add-button {
    background-color: #4a77a8;
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .add-button:hover {
    background-color: #3a5f84;
  }